<template>
	<div>
		<div class="modify">
			<div class="thatperson" @click="switc(1)" :style="{'color': color1,'background': background1}">
				个人用户修改密码
			</div>
			<div class="group" @click="switc(2)" :style="{'color': color2,'background': background2}">
				团体用户修改密码
			</div>
		</div>
		<div class="title">
			<img src="../assets/image/modify.png" alt="">
			<p><span style="display: block;height: 6px;">请输入您的手机号和验证码，确认身份。</span><br><span>如有技术问题，请拨打以下电话与网站技术联系。联系电话：010-63827176，咨询时间：工作日（周一至周五）9:00 - 17:00</span></p>
		</div>
		<div class="back">
			<div class="tel">
				<img src="../assets/image/tel.png" alt="">
				<p class="telnumber">手机号</p>
				<p class="asterisk">*</p>
				<input type="text" style="margin-left: 46PX;" v-model="info.phoneNum">
				<div class="but" @click="startCountdown" :disabled="isCountingDown">
					{{ buttonText }}
				</div>
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/code.png" alt="" style="width: 20.2PX;height: 23.9PX;">
				<p class="telnumber">短信验证码</p>
				<p class="asterisk">*</p>
				<input type="text" style="margin-left: 7PX;" v-model="info.codeNum">
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/password.png" alt="" style="width: 20.2PX;height: 23.9PX;">
				<p class="telnumber">新密码</p>
				<p class="asterisk">*</p>
				<el-input v-model="info.newPassword" show-password style="margin-left: 44PX;"></el-input>
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/confirm.png" alt="" style="width: 20.2PX;height: 23.9PX;">
				<p class="telnumber">确认密码</p>
				<p class="asterisk">*</p>
				<el-input v-model="info.confirm" show-password style="margin-left: 26PX;"></el-input>
			</div>
			<div class="submitto" @click="submitto">
				提交
			</div>
		</div>
		<!-- <div class="support">
			<img :src="this.$store.state.img" alt="">
			<p>微信技术服务</p>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				color1: '#FFFFFF',
				color2: '#0063FF',
				background1: '#0063FF',
				background2: '#ECF1FD',
				info: {
					phoneNum: '',
					userType: 'user_type_01',
					newPassword: '',
					codeNum: '',
					confirm:''
				},

				isCountingDown: false,
				remainingSeconds: 0,
				countdownTimer: null,
				isCountingDown2:false
			}
		},
		created() {},
		computed: {
			buttonText() {
				if (this.isCountingDown) {
					return `${this.remainingSeconds} 秒后重新获取`;
				} else {
					return '获取验证码';
				}
			},
		},
		methods: {
			switc(e) {
				if (e == 1) {
					this.color1 = '#FFFFFF',
					this.color2 = '#0063FF',
					this.background1 = '#0063FF',
					this.background2 = '#ECF1FD',
					this.info.userType = 'user_type_01'
				} else {
					this.color1 = '#0063FF',
					this.color2 = '#FFFFFF',
					this.background1 = '#ECF1FD',
					this.background2 = '#0063FF',
					this.info.userType = 'user_type_02'
				}
			},
			startCountdown() {
				//  return this.$message.error('短信验证超出规定次数')
				if (this.info.phoneNum == '') {
					return this.$message.error('请输入手机号码')
				}
				const regex = /^1[3456789]\d{9}$/
				if (!regex.test(this.info.phoneNum)) {
					return this.$message.error('手机号码格式错误')
				}
				if(this.isCountingDown2){
					return;
				}
				if (this.isCountingDown) {
					return;
				}
				let data = {
					phone: this.info.phoneNum,
					timeOut: 60,
					smsmode:2 //忘记密码
				}
				this.isCountingDown2 = true
				this.$api.getPhoneCode(data).then(res => {
					this.isCountingDown = true;
					this.remainingSeconds = 60;

					this.countdownTimer = setInterval(() => {
						this.remainingSeconds--;

						if (this.remainingSeconds <= 0) {
							this.stopCountdown();
						}
					}, 1000);
				}).catch(error => {
					this.isCountingDown2 = false;
					console.error('Failed to request verification code:', error);
				})
			},
			stopCountdown() {
				clearInterval(this.countdownTimer);
				this.isCountingDown = false;
				this.remainingSeconds = 0;
				this.isCountingDown2 = false;
			},
			submitto() {
				if (this.info.phoneNum == '') {
					return this.$message.error('请输入手机号码')
				}
				const regex = /^1[3456789]\d{9}$/
				if (!regex.test(this.info.phoneNum)) {
					return this.$message.error('手机号码格式错误')
				}
				if(this.info.codeNum == ''){
					return this.$message.error('请输入验证码')
				}
				
				const pas = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*_-]).{8,16}$/
				if (this.info.newPassword == '') {
					return this.$message.error('请输入密码')
				}
				if (!pas.test(this.info.newPassword)) {
					return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
				}
				if (this.info.confirm == '') {
					return this.$message.error('请输入确认密码')
				}
				if (!pas.test(this.info.confirm)) {
					return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
				}
				
				if (this.info.newPassword != this.info.confirm) {
					return this.$message.error('确认密码输入错误，不一致')
				}
				let data = {
					phoneNum: this.$encrypt(this.info.phoneNum),
					userType: this.$encrypt(this.info.userType),
					newPassword: this.$encrypt(this.info.newPassword),
					codeNum: this.$encrypt(this.info.codeNum),
					confirm: this.$encrypt(this.info.confirm)
				}
				this.$api.userResettingPwd(data).then(res => {
					if(res.data.code == 200){
						this.$message.success('操作成功')
						setTimeout(() => {
							this.$router.push({
								path: '/login'
							})
						})
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.modify {
		display: flex;
		width: 1200PX;
		margin: 0 auto;
		margin-top: 65PX;
		border-bottom: 2PX solid #0063FF;
	}

	.modify .thatperson {
		width: 317PX;
		height: 50PX;
		background: #0063FF;
		border-radius: 12PX 12PX 0PX 0PX;
		font-size: 20PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.modify .group {
		width: 317PX;
		height: 50PX;
		background: #ECF1FD;
		border-radius: 12PX 12PX 0PX 0PX;
		margin-left: 13PX;
		font-size: 20PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.title {
		width: 1200PX;
		margin: 0 auto;
		display: flex;
		height: 78PX;
		background: #F1F6FF;
		border: 1PX solid #2368E1;
		border-radius: 16PX;
		margin-top: 37PX;
		align-items: center;
	}

	.title img {
		width: 71PX;
		height: 71PX;
		margin-left: 98PX;
	}

	.title p {
		font-size: 18PX;
		font-weight: 400;
		color: #333333;
		margin-left: 20PX;
	}

	.back {
		width: 1200PX;
		margin: 0 auto;
		background: #F8FBFF;
		border-radius: 22PX;
		margin-top: 32PX;
		padding-bottom: 40PX;
	}

	.tel {
		display: flex;
		align-items: center;
		padding-top: 114PX;
		margin-left: 285PX;
	}

	.tel img {
		width: 17.3PX;
		height: 27.7PX;
	}

	.telnumber {
		font-size: 18PX;
		font-weight: 400;
		color: #333333;
		margin-left: 25PX;
	}

	.tel .asterisk {
		font-size: 25PX;
		font-weight: 400;
		color: #E6101C;
		margin-left: 5PX;
	}

	.tel input {
		width: 312PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #164B92;
		border-radius: 7PX;
		padding-left: 10PX;
		font-size: 18PX;
	}
	
	.el-input {
		width: 312PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #164B92;
		border-radius: 7PX;
		padding-left: 10PX;
		font-size: 18PX;
	}
	::v-deep .el-input__inner{
		width: 100%;
		height: 41PX;
		border: none;
		padding:0;
		color: #000;
	}

	.tel .but {
		width: 145PX;
		height: 42PX;
		background: #0063FF;
		border-radius: 7PX;
		font-size: 18PX;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 22PX;
		cursor: pointer;
	}

	.submitto {
		width: 654PX;
		height: 54PX;
		background-image: linear-gradient(to right, #6FB2FF, #095FFF);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 25PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 288PX;
		margin-top: 43PX;
		cursor: pointer;
	}

	.support {
		width: 1200PX;
		margin: 0 auto;
		height: 223PX;
		background: #F8FBFF;
		border-radius: 22PX;
		display: flex;
		margin-top: 44PX;
		margin-bottom: 170PX;
		align-items: center;
	}

	.support img {
		width: 149PX;
		height: 149PX;
		margin-top: 30PX;
		margin-left: 227PX;
	}

	.support p {
		font-size: 18PX;
		font-weight: 400;
		color: #333333;
		margin-left: 98PX;
	}
</style>